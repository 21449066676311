// Import animation, motion
import { motion } from 'framer-motion';
import { containerVariants, child } from '../../utils/animations';

import { RiCheckFill } from 'react-icons/ri';

const Multiple = ({
  data,
  images,
  isFormSubmitted,
  clickHandlerMultiple,
  areAnswersCorrect,
  handleSubmit,
  answersClicked,
}) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="multiple"
    >
      <motion.div variants={child} className="lead">
        <p className="fw-normal">{data.questionDescription}</p>
        <p className="fw-bold mb-1 pt-2 px-2">{data.questionText}</p>
        <p className="multi-text pb-2">(Moguće je više odgovora)</p>
      </motion.div>
      <div className="row g-0 question-wrapper">
        <motion.div
          variants={child}
          className="question-image col-12 col-md-5 d-flex justify-content-center align-items-center"
        >
          <img src={images[data.questionImage]} alt="question" />
        </motion.div>

        <motion.div variants={child} className="col-12 col-md-7 d-flex align-items-center">
          <div className="answer-section">
            {data.answerOptions.map((answer, index) => (
              <div key={'multi-answer_' + index} className="col-12 text-center">
                <button
                  id={'answer_' + index}
                  type="button"
                  className={`btn btn-answer${
                    answersClicked.includes('answer_' + index) ? ' selected' : ''
                  }`}
                  onClick={(e) => clickHandlerMultiple(e, answer.is_correct)}
                >
                  {answer.answerText}

                  {isFormSubmitted && (
                    <>
                      {answersClicked.includes('answer_' + index) && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: areAnswersCorrect ? 1 : 0 }}
                          transition={{ delay: areAnswersCorrect ? 0 : 1 }}
                          className="animation animation__correct"
                        >
                          <motion.span
                            initial={{ opacity: 0, scale: 3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                          >
                            <RiCheckFill className="icon" />
                          </motion.span>
                        </motion.span>
                      )}
                      {!areAnswersCorrect && answer.is_correct && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                          className="animation animation__correct"
                        >
                          <motion.span
                            initial={{ opacity: 0, scale: 3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                          >
                            <RiCheckFill className="icon" />
                          </motion.span>
                        </motion.span>
                      )}
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div variants={child} className="d-flex justify-content-center mt-2 mt-md-5">
          {data.type === 'multi-text' && (
            <button type="button" className="btn btn-next btn-main" onClick={handleSubmit}>
              DALJE
            </button>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Multiple;
